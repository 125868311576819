<template>
  <div class="container bgblue">
    <div class="header">
      <el-row>
        <el-form :model="form" class="demo-form-inline" label-width="80px">
          <el-col :span="6">
            <el-form-item label="提货日期:">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="changeDate"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运单号:">
              <el-input
                v-model="form.specs"
                placeholder="请输入运单号:"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="20px">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >查询</el-button
              >
              <el-button icon="el-icon-refresh" @click="onReset"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="table">
      <div class="btns">
        <el-button type="primary"> 申请提货 </el-button>
        <el-button type="primary">取消提货</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" border height="90%">
        <el-table-column align="center" prop="" label="操作">
          <template v-slot="{ row }">
            <el-button type="text"  class="btn-blue"    @click="handelDetail(row);showDialog = true"
              >详情</el-button
            >
          </template>
        </el-table-column>

        <el-table-column align="center" prop="specs" label="运单号">
        </el-table-column>
        <el-table-column align="center" prop="productName" label="提货日期">
        </el-table-column>
        <el-table-column align="center" prop="num" label="发货仓库">
        </el-table-column>
        <el-table-column align="center" prop="unit" label="车牌号">
        </el-table-column>
        <el-table-column align="center" prop="unit" label="司机">
        </el-table-column>
        <el-table-column align="center" prop="unit" label="死机电话">
        </el-table-column>
        <el-table-column align="center" prop="unit" label="包含单据+用户">
        </el-table-column>
      </el-table>
      <!-- 详情弹框 -->
      <div id="dialog">
      <el-dialog title="运单明细" :visible.sync="showDialog" center >
        <div id="pdf">
          <el-row :gutter="20">
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800;">运单号: </span>
                <span>YHF20230306165224385</span>
              </div></el-col
            >
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800;">提货日期:</span> <span>2023-03-07</span>
              </div></el-col
            >
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800;">车牌号: </span>
                <span>皖SF9356</span>
              </div></el-col
            >
            <el-col :span="12"
              ><div>
                <span style="font-weight: 800;">司机:</span> <span>周刚</span>
              </div></el-col
            >
          </el-row>
          <el-table :data="tabData"  border   height="calc(100vh - 610px)">
      
        <el-table-column align="center" prop="num" label="交货单号">
        </el-table-column>
        <el-table-column align="center" prop="date" label="接单日期">
        </el-table-column>
        <el-table-column align="center" prop="" label="操作" fixed  width="100px">
          <el-button type="text" class="btn-blue">下载明细</el-button>
        </el-table-column>
        
      </el-table>
        </div>
       <el-row type="flex" justify="center" style="margin-top: 10px;">
        <el-button  @click="showDialog=false" style="margin-left: 20px;" type="primary">确定</el-button>
        <el-button @click="showDialog=false">取消</el-button>
       
       </el-row>
      </el-dialog>
    </div>
      <div class="page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.currPage"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totol"
          style="margin-top: 3px;"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import baseURL from "../../utils/baseURL"
export default {
  data() {
    return {
      tableData: [{}],
      tabData:[
        {num:"XSDDTRP2023030617806-001",date:"2023-03-06",},
        {num:"XSDDTRP2023030617806-001",date:"2023-03-06",},
      ],
      showDialog: false,
      form: {
        min: "",
        max: "",
        productName: "",
        specs: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
    };
  },
  methods: {
    onSubmit() {
      this.params.currPage = 1;
      this.getData();
    },
    async getData() {
      // const {
      //   data: { data },
      // } = await this.$http.post("/houseStoreInfo/list", {
      //   condition: this.form,
      //   ...this.params,
      // });
      // console.log(data, 169);
      // this.totol = data.count;
      // this.tableData = data.data;
    },
    onReset() {
      this.form = {};
      this.getData();
    },
    handelDetail(row) {},
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;
::v-deep .el-table{
  margin-top: 10px !important;
}
::v-deep .el-form-item__label{
    font-size: 13px;
   

  }
::v-deep .el-table td {
      padding: 0.5px 0;
      box-sizing: border-box;
    }
   ::v-deep .el-dialog__header{
      background-color: #f7f9fc;
    }
    #pdf {
  background-color: #fcfdfe !important;
  padding: 20px;
      .el-row{
        line-height: 25px;
      }
  box-sizing: border-box;
}
  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 0px !important;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #ccc;
    ::v-deep .el-select {
      width: 100% !important;
    }

    .count {
      display: flex;
      ::v-deep .el-input__inner {
        width: 100%;
        height: 35px !important;
        line-height: 35px !important;
      }
    }

    ::v-deep .el-input__inner {
      width: 100%;
      height: 35px !important;
      line-height: 35px !important;
    }
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    height: calc(100vh - 140px);

    ::v-deep .el-button--primary.is-plain {
      height: 20px !important;
      line-height: 6px !important;
    }

   
    .btns {
      display: flex;
    }
  }
  .page {
    width: 100%;
    text-align: end;
  }
  .dialog {
    width: 100%;
    text-align: center;

    ::v-deep .el-button {
      height: 35px !important;
    }

    .img {
      width: 150px;
      height: 150px;
      margin: 10px;
    }
  }
}

.warningBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 40px;
  line-height: 40px;
  background-color: #f59a23;
  color: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  .icon {
    width: 20px;
    height: 20px;
  }
}

::v-deep .el-dialog__body {
  padding: 0 0 30px 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}
</style>
